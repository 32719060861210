<template>
  <button
    class="btn btn-primary w-25 font-weight-bolder font-size-sm loading"
    :class="{disabled: isLoading}"
    @click="isLoading"
  >
    <i v-if="isLoading" class="overflow-hidden">
      <vue-loaders-ball-clip-rotate-pulse
        class="d-inline pr-5 m-0"
        color="red" scale="0.75">
      </vue-loaders-ball-clip-rotate-pulse>
    </i>
    <span v-else>{{ title }}</span>

  </button>
</template>

<script>

export default {
name: "SpinnerButton",
  props: ['isLoading', 'title', 'evenName'],

  methods: {
    onClick(){
      this.$emit(this.evenName);
    }
  }
}
</script>

<style scoped>

</style>
