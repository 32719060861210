var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('dashboard-box',{attrs:{"back":true,"back-component-name":"sample.create.meeting-list"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.meeting.name))])]},proxy:true},{key:"preview",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('p',{staticClass:"text-center text-primary"},[_c('span',{staticClass:"d-inline-block mr-4"},[_c('span',{staticClass:"d-flex items-center font-weight-bolder"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.meeting.calender', {
                  class: 'w-25px h-25px object-cover mr-2 d-inline-block'
                }))}}),_vm._v(" "+_vm._s(_vm._f("momentDateDayFormat")(_vm.meeting.date_hour))+" ")])]),_c('span',{staticClass:"d-inline-block"},[_c('span',{staticClass:"d-flex items-center font-weight-bolder"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.meeting.clock', {
                      class: 'w-25px h-25px object-cover mr-2 d-inline-block'
                }))}}),_vm._v(" "+_vm._s(_vm._f("momentHourAndMinuteFormat")(_vm.meeting.date_hour))+" ")])])]),(_vm.meeting.company)?_c('h3',{staticClass:"text-center mb-2 pb-2",staticStyle:{"border-bottom":"2px solid rgba(150, 150, 150, 0.6)"}},[_c('img',{staticClass:"company-thumbnail-photo rounded-circle w-35px h-35px company-image-border object-contain",attrs:{"src":_vm.meeting.company.avatar_file}}),_vm._v(" "+_vm._s(_vm.meeting.company.name)+" ")]):_vm._e()]),_c('h3',{directives:[{name:"b-toggle",rawName:"v-b-toggle.meeting_attendees",modifiers:{"meeting_attendees":true}}],staticClass:"cursor-pointer pb-2 mb-5 d-flex justify-content-center items-center",staticStyle:{"border-bottom":"2px solid rgba(150, 150, 150, 0.6)","align-items":"center"},attrs:{"variant":"info"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.meeting.meeting-participant-1', {class: 'w-35px h-35px object-cover d-inline-block mr-3'}))}}),_c('span',[_vm._v(_vm._s(_vm.$t('editSample.meeting_attendees')))]),_c('i',{staticClass:"fas fa-caret-down ml-3"})]),_c('b-collapse',{staticClass:"row pb-5 px-10",attrs:{"id":"meeting_attendees"}},_vm._l((_vm.sampleUsers),function(user){return _c('div',{staticClass:"col-12 px-2 py-2 d-flex align-items-top justify-content-between cursor-pointer",staticStyle:{"border-bottom":"1px solid rgba(156, 163, 175, 0.2)"}},[_c('div',{staticClass:"d-flex items-baseline align-items-center justify-content-start cursor-pointer"},[_c('span',[_c('img',{staticClass:"rounded-full w-25px h-25px bg-gray-200 user-image",attrs:{"src":user.avatar_file,"alt":""}})]),_c('h6',{staticClass:"mx-4"},[_vm._v(_vm._s(user.name))])])])}),0),_c('h3',{staticClass:"my-5 text-center d-flex justify-content-center items-center"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.sample.collection-product-information', {
            class: 'w-35px h-35px object-cover d-inline-block mr-3'
          }))}}),_vm._v(" "+_vm._s(_vm.$t('createOriginalSample.collection_product_information')))]),_c('div',{staticClass:"px-4"},_vm._l((_vm.meeting.meeting_products),function(product,index){return _c('meeting-detail-accordion',{key:index,attrs:{"item-index":index + 1,"product-detail":product,"checkBoxStatuses":_vm.selectedProductsProxy},on:{"update:checkBoxStatuses":function($event){_vm.selectedProductsProxy=$event},"update:check-box-statuses":function($event){_vm.selectedProductsProxy=$event}}})}),1),_c('div',{staticClass:"d-flex align-items-end justify-content-center mt-4"},[_c('button',{staticClass:"btn btn-sm btn-outline-primary btn-pill font-weight-boldest",attrs:{"disabled":_vm.selectedProducts.length === 0},on:{"click":_vm.toggleModal}},[_vm._v(" "+_vm._s(_vm.$t("general.convert_collection_to_sample"))+" ")]),_c('modal',{attrs:{"name":"select-user-modal","adaptive":true,"height":"auto","width":"750"}},[_c('select-sample-user-modal',{attrs:{"users":_vm.sampleUsers,"model":_vm.selectedUserProxy},on:{"update:model":function($event){_vm.selectedUserProxy=$event},"onCloseModal":_vm.onCloseModal,"onConfirm":_vm.onCreateTask}})],1)],1)]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }